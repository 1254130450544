import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import {
  Autocomplete,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import goStockApi from "../../../apis/goStockApi";

const AddOrder = ({
  addAttributeHandler,
  keyword,
  handleChangeKeyword,
  handleSubmitSearch,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showSubModal, setShowSubModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [attribute, setAttribute] = useState("");
  const [openAddValue, setOpenAddValue] = useState(false);
  const [list, setList] = useState([]);
  const [value, setValue] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [valueProduct, setValueProduct] = useState(null);
  const [valueProductId, setValueProductId] = useState(null);
  const [nameOrder, setNameOrder] = useState("");
  const token = localStorage.getItem("token");
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const searchProduct = async (event) => {
    const searchValue = event.target.value;
    setSearchText(searchValue);

    try {
      const response = await goStockApi.get(
        `rfid/search/skuname/${searchValue}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Mengonversi objek respons API menjadi array
      const searchResultsArray = Object.values(response.data.data);

      setSearchResults(searchResultsArray);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const addToCart = (event) => {
    event.preventDefault();

    const newItem = {
      product: valueProduct, // Nilai produk yang dipilih dari Autocomplete
      productId: valueProductId, // Nilai produk yang dipilih dari Autocomplete
      quantity: quantity, // Nilai jumlah dari Quantity
    };

    setCartItems((prevItems) => [...prevItems, newItem]);
    setSearchText("");
    setQuantity(0);
  };

  const removeFromCart = (index) => {
    setCartItems((prevCartItems) => {
      const newCartItems = [...prevCartItems];
      newCartItems.splice(index, 1);
      return newCartItems;
    });
  };

  console.log(cartItems);

  const handleAddSubmit = async (event) => {
    event.preventDefault();

    const requestData = {
      name: nameOrder,
      note: "note",
      data: cartItems.map((item) => ({
        product_id: item.productId,
        qty: item.quantity,
      })),
    };

    await goStockApi
      .post("order", requestData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response);
        Toast.fire({
          icon: "success",
          title: `added added successfully`,
        });

        setCartItems([]);
        setNameOrder("");
        setShowModal(false);
      });
  };

  const handleAddItem = () => {
    if (list.includes(value)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "This item is already in the list",
      });
    } else if (value === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "This item value empty",
      });
    } else {
      setList((prevList) => Array.from(new Set(prevList.concat(value))));
      setValue("");
    }
  };

  const handleDeleteItem = (deletedItem) => {
    setList(list.filter((item) => item !== deletedItem));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await addAttributeHandler({
        name: attribute,
        value: list.join(","),
      })
        .then((response) => {
          setAttribute("");
          setList([]);
          setIsLoading(false);
          setShowModal(false);
          setOpenAddValue(false);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Opss...",
            text: error.response.data.message,
          });
          setAttribute(attribute);
          setList(list);
          setIsLoading(false);
          console.clear();
        });
    } catch (error) {}
  };

  return (
    <div className="flex justify-end px-4">
      <div className="w-1/3">
        <div className="relative mx-auto text-gray-600 mr-10">
          <form onSubmit={handleSubmitSearch}>
            <input
              className="input input-bordered input-info input-md border-2 w-full bg-white px-5 pr-16 rounded-lg text-sm"
              type="search"
              name="search"
              placeholder="Search"
              value={keyword}
              onChange={handleChangeKeyword}
            />
            <button type="submit" className="absolute right-0 top-0 mt-4 mr-4">
              <svg
                className="text-gray-600 h-4 w-4 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 56.966 56.966"
                xmlSpace="preserve"
                width="512px"
                height="512px"
              >
                <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
              </svg>
            </button>
          </form>
        </div>
      </div>
      <div>
        <button
          className="btn btn-info mb-10 rounded-full drop-shadow-lg hover:bg-sky-50"
          type="button"
          onClick={() => setShowModal(true)}
        >
          New Order
        </button>
        {showModal ? (
          <>
            <div className="fixed inset-0 z-50 overflow-y-auto">
              <div
                className="fixed inset-0 w-full h-full bg-black opacity-40"
                onClick={() => setShowModal(false)}
              ></div>
              <div className="flex items-center h-full px-4 py-8">
                <div className="relative w-full max-w-5xl p-4 mx-auto bg-white rounded-md shadow-lg">
                  <div className="mt-3 sm:flex">
                    <div className="mt-2 text-center sm:text-left w-full">
                      <h4 className="text-lg font-medium text-gray-800 mb-5">
                        Create Order
                      </h4>
                      <hr className="mb-8" />
                      <form>
                        <div className="flex flex-row justify-end gap-4 mt-10">
                          <div className="basis-9/12">
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={searchResults}
                              getOptionLabel={(option) => option.name || ""}
                              onChange={(event, value) => {
                                setValueProduct(value.name);
                                setValueProductId(value.id);
                              }}
                              size="small"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Product"
                                  value={searchText}
                                  onChange={searchProduct}
                                />
                              )}
                            />
                          </div>
                          <div className="basis-1/6">
                            <TextField
                              id="outlined-basic"
                              label="Quantity"
                              variant="outlined"
                              type="number"
                              size="small"
                              value={quantity}
                              onChange={handleQuantityChange}
                            />
                          </div>
                          <div className="basis-1/6">
                            <button
                              className="delay-200 bg-sky-500 h-10 rounded-full border-none text-white hover:bg-sky-600 w-full px-3 transition duration-150 ease-in-out transform active:scale-75 transition-transform"
                              onClick={addToCart}
                            >
                              Add To Cart
                            </button>
                          </div>
                        </div>
                      </form>
                      <div className="basis-1/6 mt-12">
                        <TextField
                          id="outlined-basic"
                          label="Name Order"
                          variant="outlined"
                          type="text"
                          size="small"
                          value={nameOrder}
                          onChange={(e) => setNameOrder(e.target.value)}
                        />
                      </div>

                      <TableContainer component={Paper} className="mt-5">
                        <Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          size="small"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{ paddingTop: 0, paddingBottom: 0 }}
                                width="10%"
                              >
                                #
                              </TableCell>
                              <TableCell
                                sx={{ paddingTop: 0, paddingBottom: 0 }}
                                width="30%"
                              >
                                Name Order
                              </TableCell>
                              <TableCell
                                sx={{ paddingTop: 0, paddingBottom: 0 }}
                                width="30%"
                              >
                                Value
                              </TableCell>
                              <TableCell
                                sx={{ paddingTop: 0, paddingBottom: 0 }}
                                width="30%"
                              >
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {cartItems.map((item, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  "&:hover": { background: "#EEEEEE" },
                                }}
                              >
                                <TableCell
                                  sx={{
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                  }}
                                  align="left"
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                  }}
                                  align="left"
                                >
                                  {item.product}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                  }}
                                  align="left"
                                >
                                  {item.quantity}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                  }}
                                  align="left"
                                >
                                  <button
                                    className="btn btn-xs text-xs text-white rounded-full drop-shadow-2xl bg-red-400 border-none hover:bg-red-500"
                                    type="button"
                                    onClick={() => removeFromCart(index)}
                                  >
                                    Delete
                                  </button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      {isLoading ? (
                        <button className="btn loading w-full bg-gray-200 text-black mt-3">
                          loading
                        </button>
                      ) : (
                        <div className="items-center gap-2 mt-3 sm:flex">
                          <button
                            className="w-full mt-2 p-2.5 flex-1 text-white bg-sky-600 rounded-md outline-none ring-offset-2 ring-sky-800 focus:ring-2"
                            onClick={handleAddSubmit}
                          >
                            Add
                          </button>
                          <button
                            className="w-full mt-2 p-2.5 text-red-700 flex-1 rounded-md outline-none border ring-offset-2 ring-red-700 focus:ring-2"
                            onClick={() => setShowModal(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default AddOrder;
