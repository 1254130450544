import axios from "axios";

const baseUrl = "http://demo.gostockrfid.com/api/";
// const baseUrl = "http://127.0.0.1:8000/api/";

const goStockApi = axios.create({
  baseURL: baseUrl,
});

export default goStockApi;
