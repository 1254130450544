import React from "react";
import { Container, Toolbar, Typography } from "@mui/material";
import ListButton from "../ListButton";

const MainMaster = () => {
  return (
    <>
      <Toolbar />

      <Container maxWidth="xl" sx={{ mt: 4, mb: 10 }}>
        <Typography variant="h5" gutterBottom>
          Please Choose
        </Typography>
        <ListButton />
      </Container>
    </>
  );
};

export default MainMaster;
