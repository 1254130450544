import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/images/logo_text.png";
import LogoBlue from "../../assets/images/logo_text_blue.png";

const Navbar = () => {
  const [y, setY] = useState(window.scrollY);
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const token = localStorage.getItem("token");

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);
  return (
    <>
      <div
        className="navbar text-primary-content fixed"
        style={
          y > 100
            ? {
                backgroundColor: "rgb(30 58 138)",
                transition: "all 0.3s ease",
                height: "80px",
              }
            : {
                backgroundColor: "transparent",
                transition: "all 0.3s ease",
                height: "100px",
              }
        }
      >
        <div className="container mx-auto px-8">
          <div className="navbar-start">
            <div className="lg:flex-col lg:items-stretch lg:min-h-full lg:hidden lg:flex-nowrap px-0 flex flex-row flex-wrap justify-between">
              {/* Toggler */}
              <div className="flex flex-row">
                <button
                  className="cursor-pointer opacity-50 px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent "
                  style={
                    y > 100
                      ? {
                          color: "white",
                          transition: "all 0.3s ease",
                          height: "80px",
                        }
                      : {
                          color: "black",
                          transition: "all 0.3s ease",
                          height: "100px",
                        }
                  }
                  type="button"
                  onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h8m-8 6h16"
                    />
                  </svg>
                </button>
                <button
                  className="cursor-pointer px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent "
                  type="button"
                >
                  <a
                    href="/"
                    style={
                      y > 100
                        ? {
                            color: "white",
                          }
                        : {
                            color: "gray",
                          }
                    }
                  >
                    {y > 100 ? (
                      <img src={Logo} style={{ width: 100 }} />
                    ) : (
                      <img src={LogoBlue} style={{ width: 100 }} />
                    )}
                  </a>
                </button>
              </div>
              <div
                className={
                  " drop-shadow-2xl absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
                  collapseShow
                }
              >
                {/* Collapse header */}
                <div className="lg:min-w-full lg:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
                  <div className="flex flex-wrap">
                    <div className="w-6/12">
                      <Link
                        className="lg:block text-left md:pb-2 text-black text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                        to="/dashboard/product"
                      >
                        <img src={LogoBlue} style={{ width: 100 }} />
                      </Link>
                    </div>
                    <div className="w-6/12 flex justify-end">
                      <button
                        type="button"
                        className="cursor-pointer text-black opacity-50 lg:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                        onClick={() => setCollapseShow("hidden")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                {/* Navigation */}
                <nav>
                  <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                    <li className="items-center">
                      <NavLink
                        className="text-sky-800 hover:text-sky-900 py-3 font-bold block"
                        to={"/dashboard/main-dashboard"}
                      >
                        Benefits
                      </NavLink>
                    </li>
                    <li className="items-center">
                      <NavLink
                        className="text-sky-800 hover:text-sky-900 py-3 font-bold block"
                        to={"/dashboard/product"}
                      >
                        Features
                      </NavLink>
                    </li>
                    <li className="items-center">
                      <NavLink
                        className="text-sky-800 hover:text-sky-900 py-3 font-bold block"
                        to={"/dashboard/product"}
                      >
                        About Us
                      </NavLink>
                    </li>
                    <li className="items-center">
                      <NavLink
                        className="text-sky-800 hover:text-sky-900 py-3 font-bold block"
                        to={"/dashboard/product"}
                      >
                        Contacts Us
                      </NavLink>
                    </li>
                    <li className="items-center">
                      {token ? (
                        <NavLink
                          to="/dashboard/main-dashboard"
                          className="btn bg-gray-500 text-white w-full border-none hover:bg-white hover:text-gray-700"
                        >
                          Dashboard
                        </NavLink>
                      ) : (
                        <NavLink
                          to="/login"
                          className="btn bg-orange-600 text-white w-full border-none"
                        >
                          Login
                        </NavLink>
                      )}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <a
              href="/"
              className="text-xl hidden font-bold lg:flex"
              style={
                y > 100
                  ? {
                      color: "white",
                    }
                  : {
                      color: "gray",
                    }
              }
            >
              {y > 100 ? (
                <img src={Logo} style={{ width: 100 }} />
              ) : (
                <img src={LogoBlue} style={{ width: 100 }} />
              )}
            </a>
          </div>
          <div
            className="navbar-center hidden lg:flex"
            style={
              y > 100
                ? {
                    color: "white",
                  }
                : {
                    color: "gray",
                  }
            }
          >
            <a
              href="/"
              className="hover:text-gray-400 mr-5 cursor-pointer font-semibold"
            >
              Benefits
            </a>
            <a
              href="/"
              className="hover:text-gray-400 mr-5 cursor-pointer font-semibold"
            >
              Features
            </a>
            <a
              href="/"
              className="hover:text-gray-400 mr-5 cursor-pointer font-semibold"
            >
              About Us
            </a>
            <a
              href="/"
              className="hover:text-gray-400 cursor-pointer font-semibold"
            >
              Contacts Us
            </a>
          </div>
          <div className="navbar-end hidden lg:flex">
            {token ? (
              <a
                href="/dashboard/main-dashboard"
                className="btn btn-sm bg-gray-500 rounded-full text-white border-none hover:bg-gray-600 hover:text-white h-10"
              >
                Dashboard
              </a>
            ) : (
              <>
                <a
                  href="/login"
                  className="btn btn-sm bg-orange-600 rounded-full text-white border-none hover:bg-orange-700 h-10"
                >
                  Login
                </a>
                <a
                  href="/trials"
                  className="btn btn-sm bg-sky-400 rounded-full text-white border-none hover:bg-sky-500 ml-2 h-10"
                >
                  Uji Coba Gratis
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
