import {
  Autocomplete,
  Checkbox,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import Swal from "sweetalert2";
import goStockApi from "../../../../apis/goStockApi";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddProduct = ({
  addProductHandler,
  keyword,
  handleChangeKeyword,
  handleSubmitSearch,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validation, setValidation] = useState([]);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [sku, setSku] = useState("");
  const [formattedPrice, setFormattedPrice] = useState("");
  const [dataAttribute, setDataAttribute] = useState([]);
  const [dataAttributeSecond, setDataAttributeSecond] = useState([]);
  const [valueAttribute, setValueAttribute] = useState(null);
  const [selectedAttribute, setSelectedAttribute] = useState([]);
  const [valueAttributeSecond, setValueAttributeSecond] = useState(null);
  const [selectedAttributeSecond, setSelectedAttributeSecond] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);
  const [valueCategory, setValueCategory] = useState(null);
  const [listAttribute, setListAttribute] = useState([]);
  const [listAttributeSecond, setListAttributeSecond] = useState([]);
  const [file, setFile] = useState("");
  const [preview, setPreview] = useState("");

  const updateListAttribute = () => {
    if (valueAttribute) {
      // const attributeValue = valueAttribute.value
      setListAttribute(valueAttribute.value.split(","));
    }
  };

  const updateListAttributeSecond = () => {
    if (valueAttributeSecond) {
      // const attributeValue = valueAttribute.value
      setListAttributeSecond(valueAttributeSecond.value.split(","));
    }
  };

  useEffect(() => {
    updateListAttribute();
    updateListAttributeSecond();
  }, [valueAttribute, valueAttributeSecond]);

  const optionsAttribute = listAttribute.map((item) => item);
  const optionsAttributeSecond = listAttributeSecond.map((item) => item);
  const token = localStorage.getItem("token");
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    fetchAttributes();
    fetchCategories();
  }, [file]);

  // get all ddata attributes
  const fetchAttributes = async () => {
    await goStockApi
      .get(`attribute`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setDataAttribute(response.data.data);
        setValueAttribute(null);
        setDataAttributeSecond(response.data.data);
        setValueAttributeSecond(null);
      })
      .catch((error) => {
        setValidation(error.response.data);
      });
  };

  // get all data categories
  const fetchCategories = async () => {
    await goStockApi
      .get(`category`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setDataCategory(response.data.data);
        setValueCategory(null);
      })
      .catch((error) => {
        setValidation(error.response.data);
      });
  };

  // handle selected all
  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      setSelectedAttribute(optionsAttribute);
    } else {
      setSelectedAttribute([]);
    }
  };

  const handleSelectSecondAllChange = (event) => {
    if (event.target.checked) {
      setSelectedAttributeSecond(optionsAttributeSecond);
    } else {
      setSelectedAttributeSecond([]);
    }
  };

  // Handle change price
  const handlePriceChange = (event) => {
    setPrice(event.target.value);
    setFormattedPrice(
      new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      }).format(event.target.value)
    );
  };

  // Handle change image
  const handleFileChange = (event) => {
    const image = event.target.files[0];
    // if (image.size > 5242880) {
    //   console.log("File size exceeds 5 MB");
    // } else {
    // }
    setFile(image);
    setPreview(URL.createObjectURL(image));
  };

  // Add Product
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        try {
          const base64 = reader.result.split(",")[1];
          await addProductHandler({
            name: name ? name : "",
            price: price ? price : "",
            sku: sku ? sku : "",
            image: base64,
            attr1: selectedAttribute ? selectedAttribute.join(",") : null,
            attr2: selectedAttributeSecond
              ? selectedAttributeSecond.join(",")
              : null,
            attr1_id: valueAttribute ? valueAttribute.id : null,
            attr2_id: valueAttributeSecond ? valueAttributeSecond.id : null,
            category_id: valueCategory ? valueCategory.id : "",
          })
            .then((response) => {
              setName("");
              setPrice(0);
              setFormattedPrice("");
              setSku("");
              setFile(null);
              setPreview("");
              setValueAttribute(null);
              setSelectedAttribute([]);
              setValueAttributeSecond(null);
              setSelectedAttributeSecond([]);
              setValueCategory(null);
              setIsLoading(false);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Opss...",
                text: error.response.data.message,
              });
              setIsLoading(false);
            });
        } catch (error) {}
      };
    } else {
      try {
        await addProductHandler({
          name: name ? name : "",
          price: price ? price : "",
          sku: sku ? sku : "",
          image: null,
          attr1: selectedAttribute ? selectedAttribute.join(",") : null,
          attr2: selectedAttributeSecond
            ? selectedAttributeSecond.join(",")
            : null,
          attr1_id: valueAttribute ? valueAttribute.id : null,
          attr2_id: valueAttributeSecond ? valueAttributeSecond.id : null,
          category_id: valueCategory ? valueCategory.id : "",
        })
          .then((response) => {
            setName("");
            setPrice(0);
            setFormattedPrice("");
            setSku("");
            setFile(null);
            setPreview("");
            setValueAttribute(null);
            setSelectedAttribute([]);
            setValueAttributeSecond(null);
            setSelectedAttributeSecond([]);
            setValueCategory(null);
            setIsLoading(false);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Opss...",
              text: error.response.data.message,
            });
            setIsLoading(false);
          });
      } catch (error) {}
    }
  };

  return (
    <div className="flex justify-end px-4 ">
      <div className="w-1/3">
        <div className="relative mx-auto text-gray-600 mr-10 ">
          <form onSubmit={handleSubmitSearch}>
            <input
              className="input input-bordered input-info input-md border-2 w-full bg-white px-5 pr-16 rounded-lg text-sm"
              type="search"
              name="search"
              placeholder="Search"
              value={keyword}
              onChange={handleChangeKeyword}
            />
            <button type="submit" className="absolute right-0 top-0 mt-4 mr-4">
              <svg
                className="text-gray-600 h-4 w-4 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 56.966 56.966"
                xmlSpace="preserve"
                width="512px"
                height="512px"
              >
                <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
              </svg>
            </button>
          </form>
        </div>
      </div>
      <div>
        <button
          className="btn btn-info mb-10 rounded-full drop-shadow-lg hover:bg-sky-50"
          type="button"
          onClick={() => setShowModal(true)}
        >
          Add Product
        </button>
        {showModal ? (
          <>
            <div className="fixed inset-0 z-50 overflow-y-auto">
              <div
                className="fixed inset-0 w-full h-full bg-black opacity-40"
                onClick={() => setShowModal(false)}
              ></div>
              <div className="flex items-center min-h-screen px-4 py-8">
                <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                  <div className="mt-3 sm:flex">
                    <div className="mt-2 text-center sm:text-left w-full">
                      <h4 className="text-lg font-medium text-gray-800 mb-5">
                        Create Product
                      </h4>
                      <hr className="mb-8" />
                      <form onSubmit={handleSubmit}>
                        {/* Product Name */}
                        <TextField
                          id="outlined-basic"
                          label="Product Name*"
                          variant="outlined"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          sx={{ width: "100%" }}
                        />
                        {/* Price */}
                        <TextField
                          id="outlined-basic"
                          label="Price*"
                          type="number"
                          value={price}
                          onChange={handlePriceChange}
                          variant="outlined"
                          sx={{ width: "100%", mt: 3 }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                Rp.
                              </InputAdornment>
                            ),
                          }}
                        />
                        <p>Price: {formattedPrice}</p>
                        {/* SKU */}
                        <TextField
                          id="outlined-basic"
                          label="SKU"
                          variant="outlined"
                          value={sku}
                          onChange={(e) => setSku(e.target.value)}
                          sx={{ width: "100%", mt: 3 }}
                        />
                        {/* Image */}
                        <p className="mt-2 mb-2 text-[15px] leading-relaxed text-gray-500">
                          Image
                        </p>
                        {preview ? (
                          <>
                            <div className="flex justify-center">
                              <img
                                src={preview}
                                alt="Preview Img"
                                style={{ width: 100, marginTop: "10px" }}
                                className="bg-gray-200 rounded-md"
                              />
                            </div>
                            <div className="flex justify-center">
                              <Typography sx={{ marginBottom: "10px" }}>
                                Preview
                              </Typography>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <input
                          type="file"
                          accept="image/*"
                          className="file-input file-input-bordered border-gray-400 file-input-md bg-white w-full hover:border-black"
                          onChange={handleFileChange}
                        />
                        {/* Attribute 1 */}
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={dataAttribute}
                          value={valueAttribute}
                          onChange={(event, newValue) => {
                            setValueAttribute(newValue);
                          }}
                          sx={{ width: "100%", mt: 3 }}
                          getOptionLabel={(option) => option.name || ""}
                          renderInput={(params) => (
                            <TextField {...params} label="Attribute 1" />
                          )}
                        />
                        {/* Value Attribute 1 */}
                        <div className="flex justify-end">
                          <Checkbox
                            disabled={valueAttribute === null}
                            onChange={handleSelectAllChange}
                          />
                          <p className="mt-2.5">Select All</p>
                        </div>
                        <Autocomplete
                          multiple
                          disabled={valueAttribute === null}
                          value={selectedAttribute}
                          onChange={(event, newValue) => {
                            setSelectedAttribute(newValue);
                          }}
                          id="checkboxes-tags-demo"
                          options={optionsAttribute}
                          sx={{ width: "100%" }}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="Value Attribute 1" />
                          )}
                        />
                        {/* Attribute 2 */}
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo2"
                          disabled={valueAttribute === null}
                          options={dataAttributeSecond}
                          value={valueAttributeSecond}
                          onChange={(event, newValue) => {
                            setValueAttributeSecond(newValue);
                          }}
                          sx={{ width: "100%", mt: 3 }}
                          getOptionLabel={(option) => option.name || ""}
                          renderInput={(params) => (
                            <TextField {...params} label="Attribute 2" />
                          )}
                        />
                        {/* Value Attribute 2 */}
                        <div className="flex justify-end">
                          <Checkbox
                            disabled={valueAttributeSecond === null}
                            onChange={handleSelectSecondAllChange}
                          />
                          <p className="mt-2.5">Select All</p>
                        </div>
                        <Autocomplete
                          multiple
                          disabled={valueAttributeSecond === null}
                          value={selectedAttributeSecond}
                          onChange={(event, newValue) => {
                            setSelectedAttributeSecond(newValue);
                          }}
                          id="checkboxes-tags-demo2"
                          options={optionsAttributeSecond}
                          sx={{ width: "100%" }}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="Value Attribute 2" />
                          )}
                        />
                        {/* Category */}
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={dataCategory}
                          value={valueCategory}
                          onChange={(event, newValue) => {
                            setValueCategory(newValue);
                          }}
                          sx={{ width: "100%", mt: 3, mb: 5 }}
                          getOptionLabel={(option) => option.name || ""}
                          renderInput={(params) => (
                            <TextField {...params} label="Category" />
                          )}
                        />
                        {isLoading ? (
                          <button className="btn loading w-full bg-gray-200 text-black">
                            loading
                          </button>
                        ) : (
                          <div className="items-center gap-2 mt-3 sm:flex">
                            <button className="w-full mt-2 p-2.5 flex-1 text-white bg-sky-600 rounded-md outline-none ring-offset-2 ring-sky-800 focus:ring-2">
                              Add
                            </button>
                            <button
                              className="w-full mt-2 p-2.5 text-red-700 flex-1 rounded-md outline-none border ring-offset-2 ring-red-700 focus:ring-2"
                              onClick={() => setShowModal(false)}
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default AddProduct;
