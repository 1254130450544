import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLandingPage from "./components/landing-pages/MainLandingPage";
import { lazy, useEffect, useState } from "react";
import PrivateRoutes from "./components/PrivateRoutes";
import Dashboard from "./components/mui_dashboard/Dashboard";
import Master from "./components/mui_dashboard/master/Master";
import Category from "./components/mui_dashboard/master/category/Category";
import Attribute from "./components/mui_dashboard/master/attribute/Attribute";
import Product from "./components/mui_dashboard/master/product/Product";
import Trials from "./components/landing-pages/Trials";
import Order from "./components/mui_dashboard/order/Order";

const Login = lazy(() => import("./components/landing-pages/Login"));
const Register = lazy(() => import("./components/landing-pages/Register"));
const SuccessRegister = lazy(() =>
  import("./components/landing-pages/SuccessRegister")
);

function App() {
  const [isLoading, setLoading] = useState(true);

  function fakeRequest() {
    return new Promise((resolve) => setTimeout(() => resolve(), 1500));
  }

  useEffect(() => {
    fakeRequest().then(() => {
      const el = document.querySelector(".loader-container");
      if (el) {
        el.remove();
        setLoading(!isLoading);
      }
    });

    const isDarkMode = window.matchMedia("prefers-color-scheme: dark").matches;

    if (isDarkMode) {
      document.body.classList.add("light-theme");
    }
  }, []);

  if (isLoading) {
    return null;
  }
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<MainLandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/success-register" element={<SuccessRegister />} />
        <Route path="/trials" element={<Trials />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/dashboard/main-dashboard" element={<Dashboard />} />
          <Route path="/dashboard/master" element={<Master />} />
          <Route path="/dashboard/master/category" element={<Category />} />
          <Route path="/dashboard/master/attribute" element={<Attribute />} />
          <Route path="/dashboard/master/product" element={<Product />} />
          <Route path="/dashboard/order" element={<Order />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
