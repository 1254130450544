import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import { Box, Grid } from "@mui/material";

const AddAttribute = ({
  addAttributeHandler,
  keyword,
  handleChangeKeyword,
  handleSubmitSearch,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [attribute, setAttribute] = useState("");
  const [openAddValue, setOpenAddValue] = useState(false);
  const [list, setList] = useState([]);
  const [value, setValue] = useState("");

  const handleAddItem = () => {
    if (list.includes(value)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "This item is already in the list",
      });
    } else if (value === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "This item value empty",
      });
    } else {
      setList((prevList) => Array.from(new Set(prevList.concat(value))));
      setValue("");
    }
  };

  const handleDeleteItem = (deletedItem) => {
    setList(list.filter((item) => item !== deletedItem));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await addAttributeHandler({
        name: attribute,
        value: list.join(","),
      })
        .then((response) => {
          setAttribute("");
          setList([]);
          setIsLoading(false);
          setShowModal(false);
          setOpenAddValue(false);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Opss...",
            text: error.response.data.message,
          });
          setAttribute(attribute);
          setList(list);
          setIsLoading(false);
          console.clear();
        });
    } catch (error) {}
  };

  return (
    <div className="flex justify-end px-4">
      <div className="w-1/3">
        <div className="relative mx-auto text-gray-600 mr-10">
          <form onSubmit={handleSubmitSearch}>
            <input
              className="input input-bordered input-info input-md border-2 w-full bg-white px-5 pr-16 rounded-lg text-sm"
              type="search"
              name="search"
              placeholder="Search"
              value={keyword}
              onChange={handleChangeKeyword}
            />
            <button type="submit" className="absolute right-0 top-0 mt-4 mr-4">
              <svg
                className="text-gray-600 h-4 w-4 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 56.966 56.966"
                xmlSpace="preserve"
                width="512px"
                height="512px"
              >
                <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
              </svg>
            </button>
          </form>
        </div>
      </div>
      <div>
        <button
          className="btn btn-info mb-10 rounded-full drop-shadow-lg hover:bg-sky-50"
          type="button"
          onClick={() => setShowModal(true)}
        >
          Add Attribute
        </button>
        {showModal ? (
          <>
            <div className="fixed inset-0 z-50 overflow-y-auto">
              <div
                className="fixed inset-0 w-full h-full bg-black opacity-40"
                onClick={() => setShowModal(false)}
              ></div>
              <div className="flex items-center min-h-screen px-4 py-8">
                <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                  <div className="mt-3 sm:flex">
                    <div className="mt-2 text-center sm:text-left w-full">
                      <h4 className="text-lg font-medium text-gray-800 mb-5">
                        Create Attribute
                      </h4>
                      <hr className="mb-8" />
                      <form onSubmit={handleSubmit}>
                        <p className="mt-2 mb-5 text-[15px] leading-relaxed text-gray-500">
                          Attribute Name
                        </p>
                        <input
                          type="text"
                          placeholder="Attribute Name"
                          className="input input-bordered w-full bg-white text-black mb-10"
                          value={attribute}
                          onChange={(e) => setAttribute(e.target.value)}
                        />
                        <div className="flex justify-center">
                          <p className="mb-2 text-[15px] leading-relaxed text-gray-500">
                            Add List Values
                          </p>
                        </div>
                        <div className="flex justify-center">
                          {openAddValue ? (
                            <button
                              className="btn btn-error btn-sm mb-2 btn-circle drop-shadow-2xl hover:bg-sky-50 flex"
                              type="button"
                              onClick={() => setOpenAddValue(false)}
                            >
                              <CloseIcon />
                            </button>
                          ) : (
                            <button
                              className="btn btn-info btn-sm mb-2 btn-circle drop-shadow-2xl hover:bg-sky-50 flex"
                              type="button"
                              onClick={() => setOpenAddValue(true)}
                            >
                              <AddIcon />
                            </button>
                          )}
                        </div>
                        {openAddValue ? (
                          <>
                            <div className="card w-full bg-sky-400 text-primary-content">
                              <div className="card-body">
                                <p className="mt-2 mb-5 text-[15px] leading-relaxed text-white">
                                  Attribute Value
                                </p>
                                <input
                                  type="text"
                                  placeholder="Attribute Value"
                                  className="input input-bordered w-full bg-white text-black mb-2"
                                  value={value}
                                  onChange={(e) => setValue(e.target.value)}
                                />
                                <div className="card-actions justify-end">
                                  <button
                                    className="w-24 mb-2 p-2.5 flex-1 text-white bg-sky-600 rounded-md outline-none ring-offset-2 ring-sky-800 focus:ring-2"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleAddItem();
                                    }}
                                  >
                                    Add to List
                                  </button>
                                </div>

                                {list.length === 0 ? null : (
                                  <ol
                                    type="1"
                                    className="menu w-full bg-white text-sky-900 p-2 rounded-box"
                                  >
                                    {list.map((item) => (
                                      <>
                                        <li key={item} className="mr-2 ml-2">
                                          <Box
                                            sx={{
                                              flexGrow: 1,
                                              cursor: "default",
                                              "&:hover": {
                                                backgroundColor: "transparent",
                                              },
                                              "&:active": {
                                                color: "black",
                                              },
                                            }}
                                          >
                                            <Grid container spacing={0}>
                                              <Grid
                                                item
                                                lg={10}
                                                md={10}
                                                sm={10}
                                                xs={10}
                                              >
                                                {item}
                                              </Grid>
                                              <Grid
                                                item
                                                lg={2}
                                                md={2}
                                                sm={2}
                                                xs={2}
                                              >
                                                <div className="flex justify-center">
                                                  <button
                                                    className="btn btn-xs rounded-full bg-red-400 hover:bg-red-500 border-none text-white"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      handleDeleteItem(item);
                                                    }}
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fill="none"
                                                      viewBox="0 0 24 24"
                                                      strokeWidth={1.5}
                                                      stroke="currentColor"
                                                      className="w-6 h-6"
                                                    >
                                                      <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                      />
                                                    </svg>
                                                  </button>
                                                </div>
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        </li>
                                        <hr className="mb-2" />
                                      </>
                                    ))}
                                  </ol>
                                )}
                              </div>
                            </div>
                          </>
                        ) : null}

                        {isLoading ? (
                          <button className="btn loading w-full bg-gray-200 text-black mt-3">
                            loading
                          </button>
                        ) : (
                          <div className="items-center gap-2 mt-3 sm:flex">
                            <button className="w-full mt-2 p-2.5 flex-1 text-white bg-sky-600 rounded-md outline-none ring-offset-2 ring-sky-800 focus:ring-2">
                              Add
                            </button>
                            <button
                              className="w-full mt-2 p-2.5 text-red-700 flex-1 rounded-md outline-none border ring-offset-2 ring-red-700 focus:ring-2"
                              onClick={() => setShowModal(false)}
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default AddAttribute;
