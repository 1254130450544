import React, { useEffect, useState } from "react";
import { Container, Grid, Pagination, Toolbar } from "@mui/material";
import AddOrder from "./AddOrder";
import Swal from "sweetalert2";
import goStockApi from "../../../apis/goStockApi";
import TableListOrder from "./TableListOrder";

const MainOrder = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [validation, setValidation] = useState([]);
  const [dataAttribute, setDataAttribute] = useState([]);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const token = localStorage.getItem("token");
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    fetchAttributes();
  }, [page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Get All Data Category
  const fetchAttributes = async () => {
    setIsLoading(true);
    await goStockApi
      .get(`attributes`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          page: page,
          search: keyword,
        },
      })
      .then((response) => {
        setDataAttribute(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setValidation(error.response.data);
      });
  };

  // add attribute
  const addAttributeHandler = async (dataAttribute) => {
    await goStockApi
      .post("attribute", dataAttribute, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        Toast.fire({
          icon: "success",
          title: `${response.data.data.name} added successfully`,
        });
        fetchAttributes();
      });
  };

  // Delete Data Category
  const deleteAttributeHandler = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    await goStockApi
      .delete(`attribute/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        fetchAttributes();
        Toast.fire({
          icon: "success",
          title: `${response.data.data.name} success deleted!`,
        });
      });
  };

  // update attribute
  const updateAttributeHandler = async (id, name, value) => {
    const filtered = dataAttribute.data
      .filter((attribute) => attribute.id === id)
      .map((attribute) => ({ name: name, value: value }));

    await goStockApi
      .post(`attribute/${id}`, filtered[0], {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        Toast.fire({
          icon: "success",
          title: `${response.data.data.name} added successfully`,
        });
        fetchAttributes();
      });
  };

  const handleChangeKeyword = (event) => {
    setKeyword(event.target.value);
  };

  const handleSubmitSearch = (event) => {
    event.preventDefault();
    fetchAttributes();
  };

  return (
    <>
      <Toolbar />
      {/* Container */}
      <Container maxWidth="xl" sx={{ mt: 4, mb: 10 }}>
        {/* List Button For Switch Link Master */}
        {/*  */}
        <div className="mt-16">
          {/* Add Attribute and Search */}
          <AddOrder
            addAttributeHandler={addAttributeHandler}
            handleSubmitSearch={handleSubmitSearch}
            keyword={keyword}
            handleChangeKeyword={handleChangeKeyword}
          />
        </div>
        <Grid container>
          <TableListOrder
            dataAttribute={dataAttribute}
            deleteAttributeHandler={deleteAttributeHandler}
            updateAttributeHandler={updateAttributeHandler}
          />
        </Grid>
        <div className="flex justify-center px-4">
          <Pagination
            sx={{ mt: 2 }}
            count={dataAttribute.last_page}
            page={page}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            variant="outlined"
            color="primary"
          />
        </div>
      </Container>
    </>
  );
};

export default MainOrder;
