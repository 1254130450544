import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Pagination,
  Toolbar,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import ListButton from "../../ListButton";
import AddProduct from "./AddProduct";
import goStockApi from "../../../../apis/goStockApi";
import TableListProduct from "./TableListProduct";

const MainProduct = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [validation, setValidation] = useState([]);
  const [dataProduct, setDataProduct] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);
  const [dataAttribute, setDataAttribute] = useState([]);
  const [page, setPage] = useState(1);
  const [keyword, setSetKeyword] = useState("");

  const token = localStorage.getItem("token");
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    fetchProducts();
    fetchAttributes();
    fetchCategories();
  }, [page]);

  // Get all products
  const fetchProducts = async () => {
    setIsLoading(true);
    await goStockApi
      .get(`products`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          page: page,
          search: keyword,
        },
      })
      .then((response) => {
        setDataProduct(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setValidation(error.response.data);
      });
  };

  // get all ddata attributes
  const fetchAttributes = async () => {
    await goStockApi
      .get(`attribute`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setDataAttribute(response.data.data);
      })
      .catch((error) => {
        setValidation(error.response.data);
      });
  };

  // get all data categories
  const fetchCategories = async () => {
    await goStockApi
      .get(`category`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setDataCategory(response.data.data);
      })
      .catch((error) => {
        setValidation(error.response.data);
      });
  };

  const handleChangeKeyword = (event) => {
    setSetKeyword(event.target.value);
  };

  const handleSubmitSearch = (event) => {
    event.preventDefault();
    fetchProducts();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // add product
  const addProductHandler = async (dataProduct) => {
    await goStockApi
      .post("product", dataProduct, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        Toast.fire({
          icon: "success",
          title: `${response.data.data.name} added successfully`,
        });
        fetchProducts();
      });
  };

  // Delete Data Category
  const deleteProductHandler = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    await goStockApi
      .get(`product/delete/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        fetchProducts();
        Toast.fire({
          icon: "success",
          title: `${response.data.data.name} success deleted!`,
        });
      });
  };

  // update product
  const updateProductHandler = async (
    id,
    name,
    price,
    sku,
    attr1,
    attr2,
    attr1_id,
    attr2_id,
    category_id,
    image
  ) => {
    const filtered = dataProduct.data
      .filter((product) => product.id === id)
      .map((product) => ({
        name: name,
        price: price,
        sku: sku,
        attr1: attr1,
        attr2: attr2,
        attr1_id: attr1_id,
        attr2_id: attr2_id,
        category_id: category_id,
        image: image,
      }));

    await goStockApi
      .post(`product/update/${id}`, filtered[0], {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        Toast.fire({
          icon: "success",
          title: `${response.data.data.name} added successfully`,
        });
        fetchProducts();
      });
  };

  return (
    <>
      <Toolbar />

      <Container maxWidth="xl" sx={{ mt: 4, mb: 10 }}>
        <ListButton />
        <div className="mt-16">
          <AddProduct
            addProductHandler={addProductHandler}
            handleSubmitSearch={handleSubmitSearch}
            keyword={keyword}
            handleChangeKeyword={handleChangeKeyword}
          />
        </div>
        <Grid container>
          <TableListProduct
            dataProduct={dataProduct}
            dataAttribute={dataAttribute}
            dataCategory={dataCategory}
            deleteProductHandler={deleteProductHandler}
            updateProductHandler={updateProductHandler}
          />
        </Grid>
        <div className="flex justify-center px-4">
          <Pagination
            sx={{ mt: 2 }}
            count={dataProduct.last_page}
            page={page}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            variant="outlined"
            color="primary"
          />
        </div>
      </Container>
    </>
  );
};

export default MainProduct;
