import React, { useRef, useState } from "react";
import { Box, Chip, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import goStockApi from "../../../apis/goStockApi";

const TableListOrder = ({
  dataAttribute,
  deleteAttributeHandler,
  updateAttributeHandler,
}) => {
  const [item, setItem] = useState("");
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openAddValue, setOpenAddValue] = useState(false);
  const [list, setList] = useState([]);
  const [openInputValue, setOpenInputValue] = useState(-1);
  const [dataOrder, setDataOrder] = useState([]);
  const token = localStorage.getItem("token");

  const page = dataAttribute.current_page;
  const perPage = dataAttribute.per_page;
  let paginate = 0;

  if (page * perPage === 10) {
    paginate = 0;
  } else {
    paginate = page * perPage - 10;
  }

  const { id, name: newName, value: newValue } = item;
  const [name, setName] = useState(newName);
  const [value, setValue] = useState(newValue);
  const [string, setString] = useState("");

  // useEffect(() => {
  //   if (newName && newValue && typeof newValue === "string") {
  //     setList(newValue.split(","));
  //     setName(newName);
  //     setValue(newValue);
  //   }
  // }, [newName, newValue]);

  useEffect(() => {
    getAllData();
  }, []);

  const handleViewClick = (item) => {
    setSelectedItem(item);
    setShowModalUpdate(true);
  };

  const handleAddItem = () => {
    if (list.includes(string)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "This item is already in the list",
      });
    } else if (string === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "This item value empty",
      });
    } else {
      setList((prevList) => Array.from(new Set(prevList.concat(string))));
      setString("");
    }
  };

  const updateItem = (updatedItem, index) => {
    setList(list.map((item, i) => (i === index ? updatedItem : item)));
  };

  const openEdit = (index) => {
    setOpenInputValue(index);
  };

  const closeEdit = () => {
    setOpenInputValue(-1);
  };

  const handleDeleteItem = (deletedItem) => {
    setList(list.filter((item) => item !== deletedItem));
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await updateAttributeHandler(id, name, list.join(","))
        .then((response) => {
          setName("");
          setList([]);
          setIsLoading(false);
          setShowModalUpdate(false);
          setOpenAddValue(false);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          });
          setName(name);
          setList(list);
          setIsLoading(false);
        });
    } catch (error) {}
  };

  // Get All Data Category
  const getAllData = async () => {
    setIsLoading(true);
    await goStockApi
      .get(`order`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setDataOrder(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        // setValidation(error.response.data);
      });
  };

  return (
    <Grid container>
      <button
        className="btn btn-sm text-xs text-white rounded-b-none drop-shadow-2xl bg-sky-400 border-none hover:bg-sky-500"
        type="button"
        onClick={getAllData}
      >
        refresh
      </button>
      {/* Table */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }} width="10%">
                #
              </TableCell>
              <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }} width="30%">
                Order Number
              </TableCell>
              <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }} width="30%">
                Name
              </TableCell>
              <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }} width="30%">
                Status
              </TableCell>
              <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }} width="30%">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {!dataOrder || dataOrder.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{ paddingTop: 1, paddingBottom: 1 }}
                  align="center"
                  colSpan={4}
                >
                  Data Not Found
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {dataOrder.map((item, index) => {
                return (
                  <TableRow
                    key={id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&:hover": { background: "#EEEEEE" },
                    }}
                  >
                    <TableCell
                      sx={{ paddingTop: 1, paddingBottom: 1 }}
                      align="left"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      sx={{ paddingTop: 1, paddingBottom: 1 }}
                      align="left"
                    >
                      {item.order_number}
                    </TableCell>
                    <TableCell
                      sx={{ paddingTop: 1, paddingBottom: 1 }}
                      align="left"
                    >
                      {item.name}
                    </TableCell>
                    <TableCell
                      sx={{ paddingTop: 1, paddingBottom: 1 }}
                      align="left"
                    >
                      {item.status === "wait" ? (
                        <Chip
                          label={item.status}
                          color="primary"
                          size="small"
                        />
                      ) : item.status === "process" ? (
                        <Chip
                          label={item.status}
                          color="warning"
                          size="small"
                        />
                      ) : (
                        <Chip
                          label={item.status}
                          color="success"
                          size="small"
                        />
                      )}
                    </TableCell>
                    <TableCell
                      sx={{ paddingTop: 1, paddingBottom: 1 }}
                      align="left"
                    >
                      <button
                        className="btn btn-sm text-xs text-white rounded-full drop-shadow-2xl bg-sky-400 border-none hover:bg-sky-500 mr-4"
                        type="button"
                        onClick={() => handleViewClick(item.details)}
                      >
                        View
                      </button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {showModalUpdate ? (
        <>
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowModalUpdate(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full  max-w-5xl p-4 mx-auto bg-white rounded-md shadow-lg">
                <div className="mt-3 sm:flex">
                  <div className="mt-2 text-center sm:text-left w-full">
                    <h4 className="text-lg font-medium text-gray-800 mb-5">
                      Detail Order
                    </h4>
                    <hr className="mb-8" />

                    <TableContainer component={Paper} className="mt-5">
                      <Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        size="small"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{ paddingTop: 0, paddingBottom: 0 }}
                              width="10%"
                            >
                              #
                            </TableCell>
                            <TableCell
                              sx={{ paddingTop: 0, paddingBottom: 0 }}
                              width="60%"
                            >
                              Product Name
                            </TableCell>
                            <TableCell
                              sx={{ paddingTop: 0, paddingBottom: 0 }}
                              width="30%"
                            >
                              Quantity
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedItem.map((item, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                "&:hover": { background: "#EEEEEE" },
                              }}
                            >
                              <TableCell
                                sx={{
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                }}
                                align="left"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                sx={{
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                }}
                                align="left"
                              >
                                {item.product_name}
                              </TableCell>
                              <TableCell
                                sx={{
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                }}
                                align="left"
                              >
                                {item.qty}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </Grid>
  );
};

export default TableListOrder;
