import React from "react";
import ContainerLandingPage from "./ContainerLandingPage";
import Navbar from "./Navbar";

const MainLandingPage = () => {
  return (
    <>
      <Navbar />
      <ContainerLandingPage />
    </>
  );
};

export default MainLandingPage;
