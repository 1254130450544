import React, { useRef, useState } from "react";
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  Checkbox,
  TextField,
  InputAdornment,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const TableListProduct = ({
  dataProduct,
  dataAttribute,
  dataCategory,
  deleteProductHandler,
  updateProductHandler,
}) => {
  const [item, setItem] = useState("");
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openAddValue, setOpenAddValue] = useState(false);
  const [list, setList] = useState([]);
  const [openInputValue, setOpenInputValue] = useState(-1);
  const [open, setOpen] = useState(null);
  const [openRows, setOpenRows] = useState([]);
  const [listAttribute, setListAttribute] = useState([]);
  const [listAttributeSecond, setListAttributeSecond] = useState([]);

  const updateListAttribute = () => {
    if (valueAttributeFirst) {
      setListAttribute(valueAttributeFirst.value.split(","));
    }
  };

  const updateListAttributeSecond = () => {
    if (valueAttributeSecond) {
      setListAttributeSecond(valueAttributeSecond.value.split(","));
    }
  };

  // handle selected all
  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      setSelectedAttribute(optionsAttribute);
    } else {
      setSelectedAttribute([]);
    }
  };

  // handle selected all
  const handleSelectSecondAllChange = (event) => {
    if (event.target.checked) {
      setSelectedAttributeSecond(optionsAttributeSecond);
    } else {
      setSelectedAttributeSecond([]);
    }
  };

  const {
    id,
    name: newName,
    price: newPrice,
    sku: newSku,
    image: newImage,
    attr1: newAttr1,
    attr2: newAttr2,
    attr1_id: newAttr1_id,
    attr2_id: newAttr2_id,
    category_id: newCategory_id,
  } = item;

  const dataAttributeFirst = newAttr1_id
    ? dataAttribute.find((attribute) => attribute.id == newAttr1_id)
    : null;

  const dataAttributeSecond = newAttr2_id
    ? dataAttribute.find((attribute) => attribute.id == newAttr2_id)
    : null;

  const dataCategoryFirst = newCategory_id
    ? dataCategory.find((category) => category.id === newCategory_id)
    : null;

  const optionsAttribute = listAttribute.map((item) => item);
  const optionsAttributeSecond = listAttributeSecond.map((item) => item);

  const [preview, setPreview] = useState("");
  const [name, setName] = useState(newName);
  const [price, setPrice] = useState(newPrice);
  const [formattedPrice, setFormattedPrice] = useState("");
  const [sku, setSku] = useState(newSku);
  const [file, setFile] = useState("");
  const [filePreview, setFilePreview] = useState(newImage);
  const [valueAttributeFirst, setValueAttributeFirst] =
    useState(dataAttributeFirst);
  const [valueAttributeSecond, setValueAttributeSecond] =
    useState(dataAttributeSecond);
  const [valueCategory, setValueCategory] = useState(dataCategoryFirst);
  const [selectedAttribute, setSelectedAttribute] = useState([]);
  const [selectedAttributeSecond, setSelectedAttributeSecond] = useState([]);
  const page = dataProduct.current_page;
  const perPage = dataProduct.per_page;
  let paginate = 0;

  useEffect(() => {
    updateListAttribute();
    updateListAttributeSecond();
  }, [valueAttributeFirst, valueAttributeSecond]);

  useEffect(() => {
    setName(newName);
    setPrice(newPrice);
    setSku(newSku);
    setFilePreview(newImage);
    setValueAttributeFirst(dataAttributeFirst);
    setValueAttributeSecond(dataAttributeSecond);
    setValueCategory(dataCategoryFirst);
  }, [
    newName,
    newPrice,
    newSku,
    newImage,
    newAttr1,
    newAttr1_id,
    dataAttributeFirst,
    dataAttributeSecond,
    dataCategoryFirst,
  ]);

  useEffect(() => {
    if (newAttr1) {
      let selected = newAttr1.split(",");
      if (valueAttributeFirst && dataAttributeFirst) {
        if (valueAttributeFirst.id === dataAttributeFirst.id) {
          setSelectedAttribute(selected);
        } else {
          setSelectedAttribute([]);
        }
      }
    } else {
      setSelectedAttribute([]);
    }
  }, [dataAttributeFirst, valueAttributeFirst]);

  useEffect(() => {
    if (newAttr2) {
      let selected = newAttr2.split(",");
      if (valueAttributeSecond && dataAttributeSecond) {
        if (valueAttributeSecond.id === dataAttributeSecond.id) {
          setSelectedAttributeSecond(selected);
        } else {
          setSelectedAttributeSecond([]);
        }
      }
    } else {
      setSelectedAttributeSecond([]);
    }
  }, [dataAttributeSecond, valueAttributeSecond]);

  if (page * perPage === 10) {
    paginate = 0;
  } else {
    paginate = page * perPage - 10;
  }

  const handleRowClick = (id) => {
    if (openRows.includes(id)) {
      setOpenRows(openRows.filter((rowId) => rowId !== id));
    } else {
      setOpenRows([...openRows, id]);
    }
  };

  // Handle change price
  const handlePriceChange = (event) => {
    setPrice(event.target.value);
    setFormattedPrice(
      new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      }).format(event.target.value)
    );
  };

  // Handle change image
  const handleFileChange = (event) => {
    const image = event.target.files[0];
    setFile(image);
    setPreview(URL.createObjectURL(image));
  };

  const handleCloseChange = (event) => {
    setShowModalUpdate(false);
    setName(newName);
    setPrice(newPrice);
    setSku("");
    setFile("");
    setPreview("");
    setFormattedPrice("");
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        try {
          const base64 = reader.result.split(",")[1];

          await updateProductHandler(
            id,
            name,
            price,
            sku ? sku : null,
            selectedAttribute ? selectedAttribute.join(",") : null,
            selectedAttributeSecond ? selectedAttributeSecond.join(",") : null,
            valueAttributeFirst ? valueAttributeFirst.id : null,
            valueAttributeSecond ? valueAttributeSecond.id : null,
            valueCategory.id,
            base64
          )
            .then((response) => {
              setIsLoading(false);
              setShowModalUpdate(false);
              setOpenAddValue(false);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response.data.message,
              });
              setIsLoading(false);
            });
        } catch (error) {}
      };
    } else {
      try {
        await updateProductHandler(
          id,
          name,
          price,
          sku ? sku : null,
          selectedAttribute ? selectedAttribute.join(",") : null,
          selectedAttributeSecond ? selectedAttributeSecond.join(",") : null,
          valueAttributeFirst ? valueAttributeFirst.id : null,
          valueAttributeSecond ? valueAttributeSecond.id : null,
          valueCategory.id
        )
          .then((response) => {
            setIsLoading(false);
            setShowModalUpdate(false);
            setOpenAddValue(false);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message,
            });
            setName(name);
            setList(list);
            setIsLoading(false);
          });
      } catch (error) {}
    }
  };

  return (
    <Grid container>
      {/* Table */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>#</TableCell>
              <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>
                Name Product
              </TableCell>
              <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>
                Price
              </TableCell>
              <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>
                SKU
              </TableCell>
              <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {!dataProduct || dataProduct.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{ paddingTop: 1, paddingBottom: 1 }}
                  align="center"
                  colSpan={9}
                >
                  Data Not Found
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {dataProduct.data?.map((item, index) => {
                const {
                  id,
                  name,
                  price,
                  sku,
                  image,
                  attr1,
                  attr2,
                  attr1_id,
                  attr2_id,
                  category_id,
                } = item;

                const valueCategory = dataCategory.find(
                  (category) => category.id === category_id
                );
                const valueAttribute = attr1_id
                  ? dataAttribute.find((attribute) => attribute.id == attr1_id)
                  : null;

                const valueAttributeSecond = attr2_id
                  ? dataAttribute.find((attribute) => attribute.id == attr2_id)
                  : null;

                const valueAttributeOne = attr1 ? attr1.split(",") : [];
                const valueAttributeTwo = attr2 ? attr2.split(",") : [];

                return (
                  <>
                    <TableRow
                      key={id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { background: "#EEEEEE" },
                      }}
                    >
                      <TableCell>
                        {openRows.includes(id) ? (
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleRowClick(id)}
                          >
                            <KeyboardArrowUpIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleRowClick(id)}
                          >
                            <KeyboardArrowDownIcon />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{ paddingTop: 1, paddingBottom: 1 }}
                        align="left"
                      >
                        {paginate + index + 1}
                      </TableCell>

                      <TableCell
                        sx={{ paddingTop: 1, paddingBottom: 1 }}
                        align="left"
                      >
                        {name}
                      </TableCell>
                      <TableCell
                        sx={{ paddingTop: 1, paddingBottom: 1 }}
                        align="left"
                      >
                        {price}
                      </TableCell>
                      <TableCell
                        sx={{ paddingTop: 1, paddingBottom: 1 }}
                        align="left"
                      >
                        {sku}
                      </TableCell>
                      <TableCell
                        sx={{ paddingTop: 1, paddingBottom: 1 }}
                        align="left"
                      >
                        <button
                          className="btn btn-sm text-xs text-white rounded-full drop-shadow-2xl bg-sky-400 border-none hover:bg-sky-500 mr-4"
                          type="button"
                          onClick={() => {
                            setItem(item);
                            setShowModalUpdate(true);
                          }}
                        >
                          Update
                        </button>
                        <button
                          className="btn btn-sm text-xs text-white rounded-full drop-shadow-2xl bg-red-400 border-none hover:bg-red-500"
                          type="button"
                          onClick={() => deleteProductHandler(item.id)}
                        >
                          Delete
                        </button>
                      </TableCell>
                    </TableRow>
                    <TableRow className="bg-slate-50">
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse
                          in={openRows.includes(id)}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box sx={{ margin: 1 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              Detail
                            </Typography>
                            {image ? (
                              <Table size="small" aria-label="purchases">
                                <TableBody>
                                  <TableRow>
                                    <TableCell align="center">
                                      <div className="flex justify-center">
                                        <img
                                          src={image}
                                          alt={name}
                                          width={200}
                                        />
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            ) : (
                              ""
                            )}

                            <Table size="small" aria-label="purchases">
                              <TableBody>
                                <TableRow>
                                  <TableCell sx={{ fontWeight: 600 }}>
                                    Name
                                  </TableCell>
                                  <TableCell sx={{ fontWeight: 600 }}>
                                    Price
                                  </TableCell>
                                  <TableCell sx={{ fontWeight: 600 }}>
                                    SKU
                                  </TableCell>
                                  <TableCell sx={{ fontWeight: 600 }}>
                                    Category
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>{name}</TableCell>
                                  <TableCell>{price}</TableCell>
                                  <TableCell>{sku}</TableCell>
                                  <TableCell>
                                    {valueCategory ? valueCategory.name : ""}
                                  </TableCell>
                                </TableRow>
                                {attr1_id ? (
                                  <>
                                    <TableRow>
                                      <TableCell
                                        sx={{ fontWeight: 600 }}
                                        width="50%"
                                        colSpan={2}
                                      >
                                        {valueAttribute
                                          ? valueAttribute.name
                                          : ""}
                                      </TableCell>
                                      <TableCell
                                        sx={{ fontWeight: 600 }}
                                        width="50%"
                                        colSpan={2}
                                      >
                                        {valueAttributeSecond
                                          ? valueAttributeSecond.name
                                          : ""}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell width="50%" colSpan={2}>
                                        {valueAttributeOne.map((item) => (
                                          <div
                                            className="badge bg-orange-400 border-none text-white mr-1 mb-1 mt-1"
                                            key={item}
                                          >
                                            {item}
                                          </div>
                                        ))}
                                      </TableCell>
                                      <TableCell width="50%" colSpan={2}>
                                        {valueAttributeTwo.map((item) => (
                                          <div
                                            className="badge bg-orange-400 border-none text-white mr-1 mb-1 mt-1"
                                            key={item}
                                          >
                                            {item}
                                          </div>
                                        ))}
                                      </TableCell>
                                    </TableRow>
                                  </>
                                ) : (
                                  ""
                                )}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {showModalUpdate ? (
        <>
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowModalUpdate(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                <div className="mt-3 sm:flex">
                  <div className="mt-2 text-center sm:text-left w-full">
                    <h4 className="text-lg font-medium text-gray-800 mb-5">
                      Update Attribute
                    </h4>
                    <hr className="mb-8" />
                    <form onSubmit={handleEdit}>
                      {/* Product Name */}
                      <TextField
                        id="outlined-basic"
                        label="Product Name*"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      {/* Price */}
                      <TextField
                        id="outlined-basic"
                        label="Price*"
                        type="number"
                        variant="outlined"
                        sx={{ width: "100%", mt: 3 }}
                        value={price}
                        onChange={handlePriceChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Rp.
                            </InputAdornment>
                          ),
                        }}
                      />
                      <p>Price: {formattedPrice}</p>
                      {/* SKU */}
                      <TextField
                        id="outlined-basic"
                        label="SKU"
                        variant="outlined"
                        sx={{ width: "100%", mt: 3 }}
                        value={sku}
                        onChange={(e) => setSku(e.target.value)}
                      />
                      {/* Image */}
                      <p className="mt-2 mb-2 text-[15px] leading-relaxed text-gray-500">
                        Image
                      </p>
                      {preview ? (
                        <>
                          <div className="flex justify-center">
                            <img
                              src={preview}
                              alt="Preview Img"
                              style={{ width: 100, marginTop: "10px" }}
                              className="bg-gray-200 rounded-md"
                            />
                          </div>
                          <div className="flex justify-center">
                            <Typography sx={{ marginBottom: "10px" }}>
                              Preview
                            </Typography>
                          </div>
                        </>
                      ) : filePreview ? (
                        <>
                          <div className="flex justify-center">
                            <img
                              src={filePreview}
                              alt="Preview Img"
                              style={{ width: 100, marginTop: "10px" }}
                              className="bg-gray-200 rounded-md"
                            />
                          </div>
                          <div className="flex justify-center">
                            <Typography sx={{ marginBottom: "10px" }}>
                              Preview
                            </Typography>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        className="file-input file-input-bordered border-gray-400 file-input-md bg-white w-full hover:border-black"
                        onChange={handleFileChange}
                      />
                      {/* Attribute 1 */}
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={dataAttribute}
                        value={valueAttributeFirst}
                        onChange={(event, newValue) => {
                          setValueAttributeFirst(newValue);
                          updateListAttribute();
                        }}
                        sx={{ width: "100%", mt: 3 }}
                        getOptionLabel={(option) => option.name || ""}
                        renderInput={(params) => (
                          <TextField {...params} label="Attribute 1" />
                        )}
                      />

                      {/* Value Attribute 1 */}
                      <div className="flex justify-end">
                        <Checkbox
                          disabled={valueAttributeFirst === null}
                          onChange={handleSelectAllChange}
                        />
                        <p className="mt-2.5">Select All</p>
                      </div>
                      <Autocomplete
                        multiple
                        disabled={valueAttributeFirst === null}
                        value={selectedAttribute}
                        onChange={(event, newValue) => {
                          setSelectedAttribute(newValue);
                        }}
                        id="checkboxes-tags-demo"
                        options={optionsAttribute}
                        sx={{ width: "100%" }}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label="Value Attribute 1" />
                        )}
                      />

                      {/* Attribute 2 */}
                      <Autocomplete
                        disablePortal
                        disabled={valueAttributeFirst === null}
                        id="combo-box-demo"
                        options={dataAttribute}
                        value={valueAttributeSecond}
                        onChange={(event, newValue) => {
                          setValueAttributeSecond(newValue);
                          updateListAttributeSecond();
                        }}
                        sx={{ width: "100%", mt: 3 }}
                        getOptionLabel={(option) => option.name || ""}
                        renderInput={(params) => (
                          <TextField {...params} label="Attribute 2" />
                        )}
                      />

                      {/* Value Attribute 2 */}
                      <div className="flex justify-end">
                        <Checkbox
                          disabled={valueAttributeSecond === null}
                          onChange={handleSelectSecondAllChange}
                        />
                        <p className="mt-2.5">Select All</p>
                      </div>
                      <Autocomplete
                        multiple
                        disabled={valueAttributeSecond === null}
                        value={selectedAttributeSecond}
                        onChange={(event, newValue) => {
                          setSelectedAttributeSecond(newValue);
                        }}
                        id="checkboxes-tags-demo"
                        options={optionsAttributeSecond}
                        sx={{ width: "100%" }}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label="Value Attribute 2" />
                        )}
                      />

                      {/* Category */}
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={dataCategory}
                        value={valueCategory}
                        onChange={(event, newValue) => {
                          setValueCategory(newValue);
                        }}
                        sx={{ width: "100%", mt: 3, mb: 5 }}
                        getOptionLabel={(option) => option.name || ""}
                        renderInput={(params) => (
                          <TextField {...params} label="Category" />
                        )}
                      />

                      {isLoading ? (
                        <button className="btn loading w-full bg-gray-200 text-black">
                          loading
                        </button>
                      ) : (
                        <div className="items-center gap-2 mt-3 sm:flex">
                          <button className="w-full mt-2 p-2.5 flex-1 text-white bg-sky-600 rounded-md outline-none ring-offset-2 ring-sky-800 focus:ring-2">
                            Add
                          </button>
                          <button
                            className="w-full mt-2 p-2.5 text-red-700 flex-1 rounded-md outline-none border ring-offset-2 ring-red-700 focus:ring-2"
                            onClick={handleCloseChange}
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </Grid>
  );
};

export default TableListProduct;
