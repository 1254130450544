import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Pagination,
  Toolbar,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import TableListCategory from "./TableListCategory";
import AddCategory from "./AddCategory";
import goStockApi from "../../../../apis/goStockApi";
import ListButton from "../../ListButton";

const MainCategory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [validation, setValidation] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);
  const [page, setPage] = useState(1);
  const [keyword, setSetKeyword] = useState("");

  const token = localStorage.getItem("token");
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    fetchCategories();
  }, [page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Get All Data Category
  const fetchCategories = async () => {
    setIsLoading(true);
    await goStockApi
      .get(`categories`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          page: page,
          search: keyword,
        },
      })
      .then((response) => {
        setDataCategory(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setValidation(error.response.data);
      });
  };

  // Add Data Category
  const addCategoryHandler = async (dataCategory) => {
    await goStockApi
      .post("category", dataCategory, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        Toast.fire({
          icon: "success",
          title: `${response.data.data.name} added successfully`,
        });
        fetchCategories();
      });
  };

  // Delete Data Category

  const deleteCategoryHandler = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    await goStockApi
      .delete(`category/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        fetchCategories();
        Toast.fire({
          icon: "success",
          title: `${response.data.data.name} success deleted!`,
        });
      });
  };

  // update category
  const updateCategoryHandler = async (id, name) => {
    const filtered = dataCategory.data
      .filter((category) => category.id === id)
      .map((category) => ({ name: name }));

    await goStockApi
      .post(`category/${id}`, filtered[0], {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        Toast.fire({
          icon: "success",
          title: `${response.data.data.name} added successfully`,
        });
        fetchCategories();
      });
  };

  const handleChangeKeyword = (event) => {
    setSetKeyword(event.target.value);
  };

  const handleSubmitSearch = (event) => {
    event.preventDefault();
    fetchCategories();
  };

  return (
    <>
      <Toolbar />

      <Container maxWidth="xl" sx={{ mt: 4, mb: 10 }}>
        <ListButton />
        <div className="mt-16">
          <AddCategory
            addCategoryHandler={addCategoryHandler}
            handleSubmitSearch={handleSubmitSearch}
            keyword={keyword}
            handleChangeKeyword={handleChangeKeyword}
          />
        </div>
        <Grid container>
          <TableListCategory
            dataCategory={dataCategory}
            deleteCategoryHandler={deleteCategoryHandler}
            updateCategoryHandler={updateCategoryHandler}
          />
        </Grid>
        <div className="flex justify-center px-4">
          <Pagination
            sx={{ mt: 2 }}
            count={dataCategory.last_page}
            page={page}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            variant="outlined"
            color="primary"
          />
        </div>
      </Container>
    </>
  );
};

export default MainCategory;
