import React from "react";
import { Animated } from "react-animated-css";
import images from "../../assets/images/hero-img.png";

const ContainerLandingPage = () => {
  return (
    <>
      <div className="bg-[url('/src/assets/images/hero-bg.png')] bg-fixed">
        <div className="container mx-auto px-8">
          <div className="flex flex-col md:flex-row h-fit md:h-fit justify-center items-center lg:justify-center lg:items-center gap-x-7">
            <div className="basis-1/2 mr-0 xl:mr-12 lg:mr-12 md:mr-0 sm:mr-0 mt-10 lg:mt-32 md:mt-28 sm:mt-10">
              <h1 className="text-3xl xl:text-5xl lg:text-5xl md:text-3xl font-bold xl:leading-normal lg:leading-normal mb-12 text-blue-600">
                Let's Boost Your Business With The Latest Technology
              </h1>
              <p className="xl:text-xl lg:text-xl md:text-lg sm:text-base text-gray-900 leading-7 mb-12 lg:mb-24 md:mb-24 sm:mb-24">
                It's very important to record the number of goods in the
                warehouse. However, manual data colelction often causes error
                between actual number of goods and number of goods in the
                database. In order to solve this problem, we use an RFID reader
                to record inventory of goods in the warehouse. By using this
                reader, not only we reduce the time for data recording, but also
                we reduce the number of miscalculations of inventory.
              </p>
            </div>
            <div className="basis-1/2 order-first md:order-last">
              <img className="mt-28" src={images} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="container mx-auto px-8">
          <div className="flex flex-col items-stretch lg:h-fit lg:justify-center gap-x-7">
            <div className="basis-full text-center">
              <h1 className="text-3xl xl:pl-36 xl:pr-36 xl:ml-36 xl:mr-36 lg:pl-24 lg:pr-24 lg:ml-20 lg:mr-20 lg:text-5xl font-bold lg:leading-normal mb-16 text-blue-600 mt-16 md:mt-28 lg:mt-12 sm:mt-3">
                Providing Solution to Your Warehousing Problems
              </h1>
              <p className="text-lg lg:text-xl leading-7 text-gray-900">
                The application of RFID technology in the Warehouse Management
                System can help in structured data collecion. From the stock of
                goods arriving, the flow of goods in and out, the number of
                goods to the age of the stock of goods can be monitored in
                detail addition, the use of RFID can help maximize the
                management of storage space in the warehouse, and the storage
                location can be tracked specifically.
              </p>
            </div>
            <div className="basis-full mt-12 mb-12">
              <div className="flex flex-col md:flex-row gap-8 md:gap-5 mb-12">
                <div className="basis-1/3">
                  <div className="card bg-white text-gray-900 shadow-2xl static overflow-hidden h-full border-b-8 border-blue-400 hover:duration-300 hover:bg-blue-400 hover:text-white">
                    <div className="card-body items-center text-justify">
                      <h2 className="card-title">Quick and Accurate</h2>
                      <p className="">
                        RFID tags can be read through parcels in seconds for a
                        dense population. WIth a UHF RFID reader, it captures
                        item data accurately and instantly. It is error free and
                        cost effective.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="basis-1/3">
                  <div className="card bg-white text-gray-900 shadow-2xl static overflow-hidden h-full border-b-8 border-orange-400 hover:duration-300 hover:bg-orange-400 hover:text-white">
                    <div className="card-body items-center text-justify">
                      <h2 className="card-title">Efficient</h2>
                      <p className="">
                        RFID tags can be automatically scanned without being
                        under the scanner and multiple tags can be scanned
                        simultaneously. So this can be cost and time efficiency.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="basis-1/3">
                  <div className="card bg-white text-gray-900 shadow-2xl static overflow-hidden h-full border-b-8 border-green-400 hover:duration-300 hover:bg-green-400 hover:text-white">
                    <div className="card-body items-center text-justify">
                      <h2 className="card-title">Real Time</h2>
                      <p className="">
                        Real-time visibility gives you a clear view of every
                        item's current stock position and forward projection.
                        And because the system automatically alerts you to any
                        issues, and allows quick drill down from aggregated
                        views.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-100">
        <div className="container mx-auto px-8">
          <div className="flex flex-col lg:h-fit justify-center items-center items-stretch">
            <div className="text-center mb-16">
              <h1 className="text-3xl lg:text-5xl font-bold text-blue-600 mt-28 md:mt-28 lg:mt-20 sm:mt-3">
                Features
              </h1>
            </div>
            <div className="basis-full">
              <div className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-3 gap-4 mb-24 lg:justify-center">
                <div className="place-content-center">
                  <div className="card bg-white text-gray-900 shadow-2xl static overflow-hidden h-full">
                    <div className="card-body items-center text-center">
                      <h2 className="card-title">History</h2>
                      <p className="">
                        History of goods from the beginning of arrival,
                        circulation of goods, last position, and also when the
                        products is no longer in the warehouse
                      </p>
                    </div>
                  </div>
                </div>

                <div className="place-content-center">
                  <div className="card bg-white text-gray-900 shadow-2xl static overflow-hidden h-full">
                    <div className="card-body items-center text-center">
                      <h2 className="card-title">Searching</h2>
                      <p className="">
                        With RFID technology, searching for goods is easier,
                        because it can be detected even without touching the
                        goods. RFID Reader can provide sound output when the
                        goods are detected.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="place-content-center">
                  <div className="card bg-white text-gray-900 shadow-2xl static overflow-hidden h-full">
                    <div className="card-body items-center text-center">
                      <h2 className="card-title">Transfer</h2>
                      <p className="">
                        If you have multiple warehouse, we provide a transfer
                        feature to record all movement of goods between
                        warehouses.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="place-content-center">
                  <div className="card bg-white text-gray-900 shadow-2xl static overflow-hidden h-full">
                    <div className="card-body items-center text-center">
                      <h2 className="card-title">Receipt</h2>
                      <p className="">
                        Gostock can be connected to wireless printer, so it can
                        print receipts directly when making incoming, outgoing,
                        or transfers.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="place-content-center">
                  <div className="card bg-white text-gray-900 shadow-2xl static overflow-hidden h-full">
                    <div className="card-body items-center text-center">
                      <h2 className="card-title">Report</h2>
                      <p className="">
                        Make smarter decisions with real-time dynamic reports
                        that you can download anytime.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[url('/src/assets/images/hero-bg.png')]">
        <div className="container mx-auto px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="mr-12 mt-12">
              <h1 className="text-3xl lg:text-5xl font-bold leading-normal mb-12 xl:mb-12 lg:mb-12 text-blue-600 md:mt-28 lg:mt-0 sm:mt-3">
                PT. Scantech Indo Pratama
              </h1>
              <p className="text-lg lg:text-xl text-gray-900 leading-7">
                Berbekal dari kesuksesan kami dari implementasi teknologi RFID
                dalam pemenuhan kebutuhan sistem informasi di lingkungan
                laundry. Maka kami ingin berbagi produk unggulan kami Gostock
                kepada semua pelaku usaha yang membutuhkan.
              </p>
            </div>
            <div className="xl:mt-12 lg:mt-12 mt-10">
              <h1 className="text-3xl lg:text-5xl font-bold lg:leading-normal mb-12 text-blue-600 md:mt-28 lg:mt-0 sm:mt-3">
                Visi
              </h1>
              <p className="text-lg lg:text-xl text-gray-900 leading-7">
                Menjadi pilihan utama dalam sistem kelola inventory maupun asset
                di indonesia.
              </p>

              <h1 className="text-3xl lg:text-5xl font-bold lg:leading-normal mb-12  text-blue-600 xl:mt-10 lg:mt-10 mt-10">
                Misi
              </h1>
              <p className="text-lg lg:text-xl text-gray-900 leading-7 mb-28">
                Menyediakan produk dengan mutu terbaik dengan harga yang layak
                dan memberikan pelayanan prima pada seluruh pelaku usaha.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-blue-400">
        <div className="container mx-auto px-8">
          <div className="flex flex-col md:flex-row gap-4 lg:h-fit items-center gap-x-7">
            <div className="mt-5 mb-5 text-white">
              <div>
                <h1 className="text-3xl lg:text-5xl font-bold lg:leading-normal md:mt-28 lg:mt-0 sm:mt-3">
                  Download
                </h1>
              </div>
              <div className="mt-5 mb-5 text-white">
                <div className="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                  </svg>
                  <p className="text-lg lg:text-xl leading-7 mb-2 ml-2">
                    Gosctock Brochure
                  </p>
                </div>
                <div className="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                  </svg>
                  <p className="text-lg lg:text-xl leading-7 ml-2">
                    Gostock for Android v2.5
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-blue-600">
        <div className="container mx-auto px-8">
          <div className="flex flex-col lg:h-fit justify-center items-center gap-x-7">
            <div className="mt-5 mb-5 text-white">
              <h1 className="text-3xl lg:text-5xl font-bold text-center lg:leading-normal mb-5 md:mt-28 lg:mt-0 sm:mt-3">
                Simplify Your Warehouse Management
              </h1>
            </div>
            <button className="btn justify-items-center text-white rounded-full mb-10">
              Contact Us
            </button>
          </div>
        </div>
      </div>

      <div className="bg-blue-900">
        <div className="container mx-auto px-8">
          <footer className="footer flex flex-col md:flex-row gap-x-7 p-10 text-white">
            <div className="basis-1/3">
              <span className="footer-title">Information</span>
              <div className="grid grid-flow-col gap-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                  />
                </svg>

                <p>085155060977</p>
              </div>
              <div className="grid grid-flow-col gap-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                  />
                </svg>
                <p>
                  Jl. Ir. H. Juanda No.1 Jatiluhur, Kec. Purwakarta, Kabupaten
                  Purwakarta, Jawa Barat
                </p>
              </div>
              <div className="grid grid-flow-col gap-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>

                <p>support@gostockrfid.com</p>
              </div>
            </div>
            <div className="basis-1/3">
              <span className="footer-title">Company</span>
              <a href="/" className="link link-hover">
                Benefits
              </a>
              <a href="/" className="link link-hover">
                Features
              </a>
              <a href="/" className="link link-hover">
                About Us
              </a>
              <a href="/" className="link link-hover">
                Contacts Us
              </a>
            </div>
            <div className="basis-1/3">
              <span className="footer-title">Legal</span>
              <a href="/" className="link link-hover">
                Terms of use
              </a>
              <a href="/" className="link link-hover">
                Privacy policy
              </a>
              <a href="/" className="link link-hover">
                Cookie policy
              </a>
            </div>
          </footer>
          <footer className="footer px-10 py-4 border-t text-white border-base-300">
            <div className="items-center grid-flow-col">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
                className="fill-current"
              >
                <path d="M22.672 15.226l-2.432.811.841 2.515c.33 1.019-.209 2.127-1.23 2.456-1.15.325-2.148-.321-2.463-1.226l-.84-2.518-5.013 1.677.84 2.517c.391 1.203-.434 2.542-1.831 2.542-.88 0-1.601-.564-1.86-1.314l-.842-2.516-2.431.809c-1.135.328-2.145-.317-2.463-1.229-.329-1.018.211-2.127 1.231-2.456l2.432-.809-1.621-4.823-2.432.808c-1.355.384-2.558-.59-2.558-1.839 0-.817.509-1.582 1.327-1.846l2.433-.809-.842-2.515c-.33-1.02.211-2.129 1.232-2.458 1.02-.329 2.13.209 2.461 1.229l.842 2.515 5.011-1.677-.839-2.517c-.403-1.238.484-2.553 1.843-2.553.819 0 1.585.509 1.85 1.326l.841 2.517 2.431-.81c1.02-.33 2.131.211 2.461 1.229.332 1.018-.21 2.126-1.23 2.456l-2.433.809 1.622 4.823 2.433-.809c1.242-.401 2.557.484 2.557 1.838 0 .819-.51 1.583-1.328 1.847m-8.992-6.428l-5.01 1.675 1.619 4.828 5.011-1.674-1.62-4.829z"></path>
              </svg>
              <p>GoStock</p>
            </div>
            <div className="md:place-self-center md:justify-self-end">
              <div className="grid grid-flow-col gap-4">
                <a href="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="fill-current"
                  >
                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
                  </svg>
                </a>
                <a href="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="fill-current"
                  >
                    <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
                  </svg>
                </a>
                <a href="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="fill-current"
                  >
                    <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default ContainerLandingPage;
