import React, { useRef, useState } from "react";
import { Box, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";

const TableListAttribute = ({
  dataAttribute,
  deleteAttributeHandler,
  updateAttributeHandler,
}) => {
  const [item, setItem] = useState("");
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openAddValue, setOpenAddValue] = useState(false);
  const [list, setList] = useState([]);
  const [openInputValue, setOpenInputValue] = useState(-1);

  const page = dataAttribute.current_page;
  const perPage = dataAttribute.per_page;
  let paginate = 0;

  if (page * perPage === 10) {
    paginate = 0;
  } else {
    paginate = page * perPage - 10;
  }

  const { id, name: newName, value: newValue } = item;
  const [name, setName] = useState(newName);
  const [value, setValue] = useState(newValue);
  const [string, setString] = useState("");

  useEffect(() => {
    if (newName && newValue && typeof newValue === "string") {
      setList(newValue.split(","));
      setName(newName);
      setValue(newValue);
    }
  }, [newName, newValue]);

  const handleAddItem = () => {
    if (list.includes(string)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "This item is already in the list",
      });
    } else if (string === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "This item value empty",
      });
    } else {
      setList((prevList) => Array.from(new Set(prevList.concat(string))));
      setString("");
    }
  };

  const updateItem = (updatedItem, index) => {
    setList(list.map((item, i) => (i === index ? updatedItem : item)));
  };

  const openEdit = (index) => {
    setOpenInputValue(index);
  };

  const closeEdit = () => {
    setOpenInputValue(-1);
  };

  const handleDeleteItem = (deletedItem) => {
    setList(list.filter((item) => item !== deletedItem));
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await updateAttributeHandler(id, name, list.join(","))
        .then((response) => {
          setName("");
          setList([]);
          setIsLoading(false);
          setShowModalUpdate(false);
          setOpenAddValue(false);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          });
          setName(name);
          setList(list);
          setIsLoading(false);
        });
    } catch (error) {}
  };

  return (
    <Grid container>
      {/* Table */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }} width="10%">
                #
              </TableCell>
              <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }} width="30%">
                Name Attribute
              </TableCell>
              <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }} width="30%">
                Value
              </TableCell>
              <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }} width="30%">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {!dataAttribute || dataAttribute.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{ paddingTop: 1, paddingBottom: 1 }}
                  align="center"
                  colSpan={4}
                >
                  Data Not Found
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {dataAttribute.data?.map((item, index) => {
                const { id, name, value } = item;
                const valueAttribute = value.split(",");

                return (
                  <TableRow
                    key={id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&:hover": { background: "#EEEEEE" },
                    }}
                  >
                    <TableCell
                      sx={{ paddingTop: 1, paddingBottom: 1 }}
                      align="left"
                    >
                      {paginate + index + 1}
                    </TableCell>
                    <TableCell
                      sx={{ paddingTop: 1, paddingBottom: 1 }}
                      align="left"
                    >
                      {name}
                    </TableCell>
                    <TableCell
                      sx={{ paddingTop: 1, paddingBottom: 1 }}
                      align="left"
                    >
                      {valueAttribute.map((item) => (
                        <div
                          className="badge bg-orange-400 border-none text-white mr-1 mb-1 mt-1"
                          key={item}
                        >
                          {item}
                        </div>
                      ))}
                    </TableCell>
                    <TableCell
                      sx={{ paddingTop: 1, paddingBottom: 1 }}
                      align="left"
                    >
                      <button
                        className="btn btn-sm text-xs text-white rounded-full drop-shadow-2xl bg-sky-400 border-none hover:bg-sky-500 mr-4"
                        type="button"
                        onClick={() => {
                          setItem(item);
                          setShowModalUpdate(true);
                          console.clear();
                        }}
                      >
                        Update
                      </button>
                      <button
                        className="btn btn-sm text-xs text-white rounded-full drop-shadow-2xl bg-red-400 border-none hover:bg-red-500"
                        type="button"
                        onClick={() => deleteAttributeHandler(item.id)}
                      >
                        Delete
                      </button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {showModalUpdate ? (
        <>
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowModalUpdate(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                <div className="mt-3 sm:flex">
                  <div className="mt-2 text-center sm:text-left w-full">
                    <h4 className="text-lg font-medium text-gray-800 mb-5">
                      Update Attribute
                    </h4>
                    <hr className="mb-8" />
                    <form onSubmit={handleEdit}>
                      <p className="mt-2 mb-5 text-[15px] leading-relaxed text-gray-500">
                        Attribute Name
                      </p>
                      <input
                        type="text"
                        placeholder="Attribute Name"
                        className="input input-bordered w-full bg-white text-black mb-10"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <div className="flex justify-center">
                        <p className="mb-2 text-[15px] leading-relaxed text-gray-500">
                          Update List Values
                        </p>
                      </div>
                      <div className="flex justify-center">
                        {openAddValue ? (
                          <button
                            className="btn btn-error btn-sm mb-2 btn-circle drop-shadow-2xl hover:bg-sky-50 flex"
                            type="button"
                            onClick={() => setOpenAddValue(false)}
                          >
                            <CloseIcon />
                          </button>
                        ) : (
                          <button
                            className="btn btn-info btn-sm mb-2 btn-circle drop-shadow-2xl hover:bg-sky-50 flex"
                            type="button"
                            onClick={() => setOpenAddValue(true)}
                          >
                            <AddIcon />
                          </button>
                        )}
                      </div>
                      {openAddValue ? (
                        <>
                          <div className="card w-full bg-sky-400 text-primary-content">
                            <div className="card-body">
                              <p className="mt-2 mb-5 text-[15px] leading-relaxed text-white">
                                Attribute Value
                              </p>
                              <input
                                type="text"
                                placeholder="Attribute Value"
                                className="input input-bordered w-full bg-white text-black mb-2"
                                value={string}
                                onChange={(e) => setString(e.target.value)}
                              />
                              <div className="card-actions justify-end">
                                <button
                                  className="w-24 mb-2 p-2.5 flex-1 text-white bg-sky-600 rounded-md outline-none ring-offset-2 ring-sky-800 focus:ring-2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleAddItem();
                                  }}
                                >
                                  Add to List
                                </button>
                              </div>

                              {list.length === 0 ? null : (
                                <ol
                                  type="1"
                                  className="menu w-full bg-white text-sky-900 p-2 rounded-box"
                                >
                                  {list.map((item, index) => (
                                    <>
                                      <li key={index} className="mr-2 ml-2">
                                        <Box
                                          sx={{
                                            flexGrow: 1,
                                            cursor: "default",
                                            "&:hover": {
                                              backgroundColor: "transparent",
                                            },
                                            "&:active": {
                                              color: "black",
                                            },
                                          }}
                                        >
                                          <Grid container spacing={0}>
                                            <Grid
                                              item
                                              lg={8}
                                              md={8}
                                              sm={8}
                                              xs={8}
                                            >
                                              {item}
                                            </Grid>
                                            <Grid
                                              item
                                              lg={2}
                                              md={2}
                                              sm={2}
                                              xs={2}
                                            >
                                              <div className="flex justify-center">
                                                <button
                                                  className="btn btn-xs rounded-full bg-sky-400 border-none hover:bg-sky-500 text-white"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    openEdit(index);
                                                  }}
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-6 h-6"
                                                  >
                                                    <path
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                                    />
                                                  </svg>
                                                </button>
                                              </div>
                                            </Grid>
                                            <Grid
                                              item
                                              lg={2}
                                              md={2}
                                              sm={2}
                                              xs={2}
                                            >
                                              <div className="flex justify-center">
                                                <button
                                                  className="btn btn-xs rounded-full bg-red-400 hover:bg-red-500 border-none text-white"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    handleDeleteItem(item);
                                                  }}
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-6 h-6"
                                                  >
                                                    <path
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                    />
                                                  </svg>
                                                </button>
                                              </div>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                        {openInputValue === index ? (
                                          <>
                                            <Box
                                              sx={{
                                                flexGrow: 1,
                                                cursor: "default",
                                                backgroundColor:
                                                  "rgb(186 230 253)",
                                                "&:hover": {
                                                  backgroundColor:
                                                    "rgb(186 230 253)",
                                                },
                                                "&:active": {
                                                  color: "black",
                                                },
                                              }}
                                            >
                                              <Grid container spacing={0}>
                                                <Grid
                                                  item
                                                  lg={8}
                                                  md={8}
                                                  sm={8}
                                                  xs={8}
                                                >
                                                  <input
                                                    type="text"
                                                    className="input input-bordered input-info input-sm w-full max-w-xs bg-gray-100 text-black"
                                                    value={item}
                                                    autoFocus
                                                    onChange={(e) =>
                                                      updateItem(
                                                        e.target.value,
                                                        index
                                                      )
                                                    }
                                                  />
                                                </Grid>
                                                <Grid
                                                  item
                                                  lg={2}
                                                  md={2}
                                                  sm={2}
                                                  xs={2}
                                                >
                                                  <div className="flex justify-center">
                                                    <button
                                                      className="btn btn-circle btn-sm bg-green-400 hover:bg-green-500 border-none text-white"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        updateItem(item, index);
                                                        closeEdit();
                                                      }}
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        className="w-6 h-6"
                                                      >
                                                        <path
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                        />
                                                      </svg>
                                                    </button>
                                                  </div>
                                                </Grid>
                                                <Grid
                                                  item
                                                  lg={2}
                                                  md={2}
                                                  sm={2}
                                                  xs={2}
                                                >
                                                  <div className="flex justify-center">
                                                    <button
                                                      className="btn btn-circle btn-sm bg-red-400 hover:bg-red-500 border-none text-white"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        closeEdit();
                                                      }}
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        className="w-6 h-6"
                                                      >
                                                        <path
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                        />
                                                      </svg>
                                                    </button>
                                                  </div>
                                                </Grid>
                                              </Grid>
                                            </Box>
                                          </>
                                        ) : null}
                                      </li>
                                      <hr className="mb-2" />
                                    </>
                                  ))}
                                </ol>
                              )}
                            </div>
                          </div>
                        </>
                      ) : null}

                      {isLoading ? (
                        <button className="btn loading w-full bg-gray-200 text-black mt-3">
                          loading
                        </button>
                      ) : (
                        <div className="items-center gap-2 mt-3 sm:flex">
                          <button className="w-full mt-2 p-2.5 flex-1 text-white bg-sky-600 rounded-md outline-none ring-offset-2 ring-sky-800 focus:ring-2">
                            Add
                          </button>
                          <button
                            className="w-full mt-2 p-2.5 text-red-700 flex-1 rounded-md outline-none border ring-offset-2 ring-red-700 focus:ring-2"
                            onClick={() => setShowModalUpdate(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </Grid>
  );
};

export default TableListAttribute;
